export const BRAND_COLORS = {
  BLUE: "#0042FF",
  GREY: "#B1B1B1",
  ORANGE: "#FF8A00",
  GREEN: "#4BCA37",
  RUBY: "#DC1D40",
  GOLD: "#FFC700",
  WHITE: "#FFFFFF",
  SLATE: "#333333",
}

export const RECAPTCHA_SITE_KEY = "6LfHTSIpAAAAAOpWb65LaY0_4aeqaNYt7kP7UxiA"
export const TOP_OF_PAGE_ID = "top-of-page-id"
export const BOTTOM_OF_PAGE_ID = "bottom-of-page-id"
