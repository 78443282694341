import { useEffect, useRef, useState } from "react"
import { TOP_OF_PAGE_ID } from "../../app/constants"
import { KeyboardArrowDown } from "@mui/icons-material"
import { Box, Fab, Fade } from "@mui/material"
import { useScroll } from "../hooks/useScroll"

export default function GoToBottom() {
  const [visible, setVisible] = useState(false)
  const divRef = useRef<HTMLDivElement>(null)
  const { percent } = useScroll()

  useEffect(() => {
    if (!divRef.current) {
      return
    }

    if (percent > 5 && percent < 100) {
      setVisible(true)
    } else {
      setVisible(false)
    }
  }, [percent])

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const node = (event.target as HTMLDivElement).ownerDocument || document
    const anchor = node.querySelector(`#${TOP_OF_PAGE_ID}`)
    if (anchor) {
      anchor.scrollIntoView({ block: "end" })
    }
  }

  return (
    <Fade in={visible}>
      <Box
        ref={divRef}
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", top: 180, right: 4, zIndex: 1e6 }}
      >
        <Fab size="small" sx={{ bgcolor: "background.default" }}>
          <KeyboardArrowDown />
        </Fab>
      </Box>
    </Fade>
  )
}
