import { createReducer } from "@reduxjs/toolkit"
import { createAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { MediaCategory, ResultData } from "./searchAPI"

type SearchStatus = "idle" | "loading" | "failed"

export interface ServiceState {
  media: ResultData
  status: SearchStatus
  category: MediaCategory
}

export type SearchRequest = {
  value: string
  event: string
  services: object
}

function initializeState(): ServiceState {
  const q = new URLSearchParams(window.location.search)
  const defaultCategory: MediaCategory =
    (q.get("c") as MediaCategory) || "music"
  return {
    media: { term: "", event: "", results: [], error: "" },
    status: "idle",
    category: defaultCategory,
  }
}

// getData
export const getData = createAction("search/getData", (data: ResultData) => {
  return {
    payload: { data },
  }
})

// sendData
export const sendData = createAction("search/sendData", (term, category) => {
  return {
    payload: { term, category },
  }
})

export const setStatus = createAction(
  "search/setStatus",
  (status: SearchStatus) => {
    return {
      payload: { status },
    }
  },
)

export const setSearchCategory = createAction(
  "search/setSearchCategory",
  (category: MediaCategory) => {
    return {
      payload: { category },
    }
  },
)

export const getError = createAction("search/getError", (error) => {
  return {
    payload: { error },
  }
})

// Disconnect
export const disconnect = createAction("search/disconnect")

// selectors
export const selectMedia = (state: RootState) => state.search.media
export const selectStatus = (state: RootState) => state.search.status
export const selectTerm = (state: RootState) => state.search.media.term
export const selectCategory = (state: RootState) => state.search.category

export const searchReducer = createReducer(initializeState(), (builder) => {
  builder
    .addCase(getData, (state, action) => {
      state.status = "idle"
      state.media = action.payload.data
    })
    .addCase(setStatus, (state, action) => {
      state.status = action.payload.status
    })
    .addCase(setSearchCategory, (state, action) => {
      state.category = action.payload.category
    })
    .addCase(disconnect, (state, action) => {
      return initializeState()
    })
})
