import { createReducer } from "@reduxjs/toolkit"
import { createAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"

type ProcessingStatus = "idle" | "loading" | "failed"

interface FeatureState {
  status: ProcessingStatus
}

const initialState: FeatureState = {
  status: "idle",
}

export const setStatus = createAction(
  "search/setStatus",
  (status: ProcessingStatus) => {
    return {
      payload: { status },
    }
  },
)

// selectors
export const selectStatus = (state: RootState) => state.videoToGif.status
export const videoToGifReducer = createReducer(initialState, (builder) => {
  builder.addCase(setStatus, (state, action) => {
    state.status = action.payload.status
  })
})
