import { createTheme, darken, lighten } from "@mui/material/styles"
import { BRAND_COLORS } from "./constants"

import PoppinRegular from "/fonts/Poppins-Regular.ttf"

export const appTheme = createTheme({
  typography: {
    button: {
      textTransform: "none",
    },
    fontFamily: "Poppins, Roboto",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: "Poppins";
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local("Poppins"), local("Poppins-Regular"), url(${PoppinRegular}), format("ttf");
        }
      `,
    },
  },
  palette: {
    primary: {
      main: BRAND_COLORS.SLATE,
      light: lighten(BRAND_COLORS.SLATE, 0.5),
      dark: darken(BRAND_COLORS.SLATE, 0.5),
    },
    secondary: {
      main: BRAND_COLORS.GOLD,
      light: lighten(BRAND_COLORS.GOLD, 0.1),
      dark: darken(BRAND_COLORS.GOLD, 0.1),
    },
    background: {
      paper: lighten(BRAND_COLORS.SLATE, 0.93),
      default: lighten(BRAND_COLORS.SLATE, 0.99),
    },
  },
})
