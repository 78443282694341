import { getData, getError, setStatus } from "../features/search/searchSlice"
import { getServiceByCategory } from "./utils"

export const events = {
  newSearch: "newSearch",
  newData: "newData",
}

let keepOpen = true

export function createWebsocketMiddleware(url: string) {
  return (store: any) => {
    let ws: WebSocket

    const initialize = () => {
      if (!keepOpen) return

      ws = new WebSocket(url)
      ws.addEventListener("open", () => {})

      ws.addEventListener("close", () => {
        setTimeout(() => initialize(), 10_000) // 10 seconds
      })

      ws.addEventListener("error", (event) => {
        store.dispatch(getError(event))
      })

      let payload: any = null
      ws.addEventListener("message", (event) => {
        store.dispatch(setStatus("idle"))
        try {
          payload = JSON.parse(event.data)
        } catch (e) {}
        if (payload && payload.event === events.newData) {
          store.dispatch(getData(payload))
        }
      })
    }

    initialize()

    return (next: any) => async (action: any) => {
      switch (action.type) {
        case "search/sendData": {
          store.dispatch(setStatus("loading"))
          const d = action.payload
          d.value = action.payload.term
          d.event = events.newSearch
          d.services = getServiceByCategory(action.payload.category)
          const data = JSON.stringify(d)
          const opened = await waitForOpenedConnection(ws)
          if (opened) ws.send(data)
          break
        }
        case "search/disconnect": {
          keepOpen = false
          ws.close()
          break
        }
      }

      return next(action)
    }
  }
}

async function waitForOpenedConnection(socket: WebSocket, timeout = 10_000) {
  const isOpened = () => socket.readyState === WebSocket.OPEN

  if (socket.readyState !== WebSocket.CONNECTING) {
    return isOpened()
  } else {
    const intraSleep = 100
    const ttl = timeout / intraSleep // time to loop
    let loop = 0
    while (socket.readyState === WebSocket.CONNECTING && loop < ttl) {
      await new Promise((resolve) => setTimeout(resolve, intraSleep))
      loop++
    }
    return isOpened()
  }
}
