import { Avatar, Box, Container, Typography } from "@mui/material"
import { BOTTOM_OF_PAGE_ID } from "../app/constants"

export default function Footer() {
  return (
    <Box
      textAlign="center"
      component="footer"
      id={BOTTOM_OF_PAGE_ID}
      sx={{
        p: 2,
        mt: "auto",
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[400]
            : theme.palette.grey[800],
      }}
    >
      <Container
        sx={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Avatar src="/logos/orijtech.png" sx={{ mr: 2, height: "2.5rem" }} />
        <Typography variant="body1">Powered by Orijtech's Medisa!</Typography>
      </Container>
    </Box>
  )
}
