import { CssBaseline, ThemeProvider, Toolbar } from "@mui/material"
import Box from "@mui/material/Box"
import ErrorMessage from "./ErrorMessage"
import AppMenu from "../AppMenu/AppMenu"
import Footer from "../Footer"
import { appTheme } from "../../app/theme"

export default function RootBoundary() {
  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          bgcolor: "background.default",
        }}
      >
        <AppMenu />
        <Toolbar />
        <ErrorMessage />
        <Footer />
      </Box>
    </ThemeProvider>
  )
}
