import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"

import { createWebsocketMiddleware } from "./middleware"
import { searchReducer } from "../features/search/searchSlice"
import { videoToGifReducer } from "../features/videoToGif/videoToGifSlice"

const wsUrl = "wss://rsp.orijtech.com/init"
export const store = configureStore({
  reducer: {
    search: searchReducer,
    videoToGif: videoToGifReducer,
  },
  middleware: [createWebsocketMiddleware(wsUrl)],
  devTools: process.env.NODE_ENV === "development",
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
