import { useEffect, useRef, useState } from "react"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import { TOP_OF_PAGE_ID } from "../../app/constants"
import { Box, Fab, Fade } from "@mui/material"
import { useScroll } from "../hooks/useScroll"

export default function GoToTop() {
  const [visible, setVisible] = useState(false)
  const divRef = useRef<HTMLDivElement>(null)
  const { percent } = useScroll()

  useEffect(() => {
    if (!divRef.current) {
      return
    }

    console.log({ percent })
    if (percent < 100 && percent > 5) {
      setVisible(true)
    } else {
      setVisible(false)
    }
  }, [percent])

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const node = (event.target as HTMLDivElement).ownerDocument || document
    const anchor = node.querySelector(`#${TOP_OF_PAGE_ID}`)
    if (anchor) {
      anchor.scrollIntoView({ block: "start" })
    }
  }

  return (
    <Fade in={visible}>
      <Box
        ref={divRef}
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: 8, right: 4, zIndex: 1e3 }}
      >
        <Fab size="small" sx={{ bgcolor: "background.default" }}>
          <KeyboardArrowUpIcon />
        </Fab>
      </Box>
    </Fade>
  )
}
