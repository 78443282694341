import { Box, Button, Typography } from "@mui/material"

export default function ErrorMessage() {
  return (
    <Box
      sx={{
        mt: 5,
        p: 2,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      <Typography variant="h2">404</Typography>
      <Typography variant="h6">
        The page you're looking for doesn't exist.
      </Typography>
      <Typography variant="caption" sx={{ my: 1 }}>
        Use the navigation bar to access high quality content or augment videos.
      </Typography>
      <Button
        component="a"
        variant="contained"
        href="/search"
        sx={{ mt: 2 }}
        color="secondary"
        disableElevation
      >
        Search
      </Button>
    </Box>
  )
}
