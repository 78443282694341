import { MediaCategory } from "../features/search/searchAPI"

export function trimText(text = "", limit = 60): string {
  return text.slice(0, limit) + (text.length > limit ? "..." : "")
}

export function getServiceByCategory(cat: MediaCategory) {
  switch (cat) {
    case "music":
    case "song":
    case "movies":
      return { iTunes: {} }
    case "docs":
      return { arxiv: {} }
    case "img":
      return { gifs: {} }
    case "vid":
      return { youtube: {} }
    case "wiki":
      return { wikipedia: {} }
    default:
      return { iTunes: {} }
  }
}

export function getCategoryFromIndex(index: number): MediaCategory[] {
  switch (index) {
    case 0:
      return ["music", "song"]
    case 1:
      return ["img"]
    case 2:
      return ["vid"]
    case 3:
      return ["docs"]
    case 4:
      return ["movies"]
    case 5:
      return ["wiki"]
    default:
      return []
  }
}

export function getIndexFromCategories(cats: MediaCategory[]): number {
  let index = 0
  if (cats.includes("music") || cats.includes("song")) {
    return 0
  } else if (cats.includes("img")) {
    return 1
  } else if (cats.includes("vid")) {
    return 2
  } else if (cats.includes("docs")) {
    return 3
  } else if (cats.includes("movies")) {
    return 4
  } else if (cats.includes("wiki")) {
    return 5
  }
  return index
}

interface AugmentQuery {
  type: "search" | "augment"
  value: string
}

export function parseAugmentQuery(input: string): AugmentQuery {
  const t = input.trim()
  if (t.split(" ").length > 1) {
    return {
      type: "search",
      value: t,
    }
  }

  try {
    let url = new URL(t)
    return {
      type: "augment",
      value: url.toString(),
    }
  } catch (e) {}

  // default case when no video is deduced from input
  return {
    type: "search",
    value: t,
  }
}
